import { createStyles, makeStyles } from "@material-ui/core/styles"
import { ChangeEvent } from "react"
import { Colors } from "../Colors"

const useStyles = makeStyles(
  createStyles({
    textField: {
      height: "34px",
      width: "65%",
      borderRadius: "4px",
      borderColor: Colors.blue,
      marginTop: "5px",
      marginBottom: "5px",
      paddingLeft: "10px",
      fontSize: "13px",
      caretColor: Colors.green,
      border: "none",
      fontFamily: "Raleway-Medium"
    },
    placeholderTextField: {
      height: "34px",
      width: "65%",
      borderRadius: "4px",
      borderColor: Colors.blue,
      marginTop: "5px",
      marginBottom: "5px",
      paddingLeft: "10px",
      fontSize: "13px",
      caretColor: Colors.gray_a50,
      border: "none",
      fontFamily: "Raleway-Medium"
    }
  })
)

interface PropsType {
    onChange: (value: string) => void,
    placeholder: string,
    value: string,
    type: string
}

const TextField = (props: PropsType) => {
  const classes = useStyles()
    
  return (
    <input type={props.type} name={props.type} className={props.value ? classes.textField : classes.placeholderTextField} placeholder={props.placeholder} 
      value={props.value} onChange={(event: ChangeEvent<HTMLInputElement>) => { props.onChange(event.target.value)}} />
  )
}

export default TextField