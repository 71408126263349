import LocalizedStrings from "react-localization"

export const strings = new LocalizedStrings({
  en: {
    invalidEmailMessage: "Enter a valid email address!",
    unselectedCheckboxMessage: "You should accept the terms of Privacy Policy to proceed!",
    welcome: "Welcome!",
    who: "First, who are you?",
    fullName: "Full name",
    email: "Email",
    termsAccept: "I accept the terms of",
    privacyPolicy: "Privacy Policy",
    chooseItinerary: "Choose the itinerary to follow:",
    credits: "The project",
    goToFavourites: "Go to favourites",
    favourites: "Favourite artworks",
    helloUser: "Hi, {0}!",
    anonymousUser: "anonymous user",
    modifyProfile: "Edit profile",
    cancel: "Cancel",
    save: "Save",
    saveError: "Error on modifying user profile",
    send: "Send",
    noFavourites: "No favourite present",
    sendPlaceholder: "Enter an e-mail to send the list",
    removeFavouriteMessage: "Are you sure you want to remove the artwork from the favorite list?",
    emailSendFailureMessage: "There was an error sending the email",
    emailSuccessMessage: "List sent successfully",
    quizTitle: "Treasure hunt",
    quizShortDescription: "Use the clues to discover the 7 objects scattered along the itineraries and if you find them all you will win our prize!",
    savingAnswerFailureMessage: "There was an error saving the answer",
    clueNumber: "Clue {0}",
    yourAnswer: "Your answer is",
    answerInstruction1: "Go find “{0}” in  ",
    answerInstruction2: ". Open its card and click on the button to check if you answered correctly.",
    viewQuestion: "view question",
    waiting: "Waiting",
    done: "Done",
    congrats: "Congratulations!",
    ohno: "Oh, no!",
    oops: "Oops!",
    yay: "Yay!",
    wrongHotspot: "This hotspot doesn’t match your answer in Clue {0}. Go to the hotspot that matches your answer.",
    skippedQuestion: "This hotspot does not match any of the answers in the current question.",
    rightAnswer: "is the right answer",
    wrongAnswer: "is not the right answer",
    tryAgain: "try again",
    treasureFound: "You've found the treasure!",
    fillMissingFields: "Fill the fields below to collect your prize",
    invalidFieldsMessage: "Please fill both fields to proceed",
    collectPrize: "Collect prize",
    victoryCongrats: "Congrats,",
    treasureYours: "The treasure is yours!",
    virtualMessage: "You now have a reduced ticket to visit Fisica Experience museum!",
    screeshotVirtual: "Take a screenshot of this screen and show it at the ticket office on your visit day.",
    screeshotReal: "To collect your prize, take a screenshot of this page and go to the Museum's Book Shop.",
    close: "Close",
    noModelFound: "No model found",
    loaded: "loaded"
  },
  it: {
    invalidEmailMessage: "Inserire un indirizzo email valido!",
    unselectedCheckboxMessage: "Accettare i termini della Privacy Policy per procedere!",
    welcome: "Benvenuto!",
    who: "Prima di tutto, chi sei?",
    fullName: "Nome e cognome",
    email: "Email",
    termsAccept: "Accetto i termini di",
    privacyPolicy: "Privacy Policy",
    chooseItinerary: "Scegli l'itinerario da seguire:",
    credits: "Il progetto",
    goToFavourites: "Vai nei preferiti",
    favourites: "Opere Preferite", 
    helloUser: "Ciao, {0}!",
    anonymousUser: "utente anonimo",
    modifyProfile: "Modifica il profilo",
    cancel: "Annulla",
    save: "Salva",
    saveError: "Errore durante la modifica del profilo utente",
    send: "Invio",
    noFavourites: "Nessun preferito presente",
    sendPlaceholder: "Inserisci una mail per inviare l'elenco",
    removeFavouriteMessage: "Sei sicuro di voler rimuovere l'opera dalla lista dei preferiti?",
    emailSendFailureMessage: "Si è verificato un errore durante l'invio dell'e-mail",
    emailSuccessMessage: "Lista inviata con successo",
    quizTitle: "Caccia al tesoro",
    quizShortDescription: "Usa gli indizi per scoprire i 7 oggetti sparsi nei percorsi e se li individuerai tutti vincerai il nostro premio!",
    savingAnswerFailureMessage: "Si è verificato un errore durante il salvataggio della risposta",
    yourAnswer: "La tua risposta è",
    answerInstruction1: "Vai a trovare “{0}” nel ",
    answerInstruction2: ". Apri la sua scheda e clicca sul pulsante per verificare se hai risposto bene.",
    viewQuestion: "vedi domanda",
    waiting: "In attesa",
    done: "Fatto",
    clueNumber: "Indizio {0}",
    congrats: "Congratulazioni!",
    ohno: "Oh, no!",
    oops: "Oops!",
    yay: "Yay!",
    wrongHotspot: "Questo hotspot non corrisponde alla tua risposta nell'indizio {0}. Vai all'hotspot che corrisponde alla tua risposta.",
    skippedQuestion: "Questo hotspot non corrisponde a nessuna delle risposte della domanda corrente.",
    rightAnswer: "è la risposta giusta",
    wrongAnswer: "non è la risposta giusta",
    tryAgain: "riprova",
    treasureFound: "Hai trovato il tesoro!",
    fillMissingFields: "Compila i campi sottostanti per ritirare il tuo premio",
    invalidFieldsMessage: "Si prega di compilare entrambi i campi per procedere",
    victoryCongrats: "Congratulazioni,",
    treasureYours: "Il tesoro è tuo!",
    virtualMessage: "Ora hai un biglietto ridotto per visitare il museo Fisica Experience!",
    screeshotVirtual: "Fai uno screenshot di questa schermata e mostrala in biglietteria il giorno della tua visita.",
    screeshotReal: "Per ritirare il tuo premio, fai uno screenshot di questa pagina e vai al Book Shop del Museo.",
    collectPrize: "Ritira il premio",
    close: "Chiudi",
    noModelFound: "Nessun modello trovato",
    loaded: "caricato"
  }
})