import { useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import "./App.css"
import { PATH_CREDITS, PATH_FAVOURITES, PATH_HOME, PATH_MODEL3D, PATH_PRIVACY_POLICY, PATH_QUIZ_ANSWER, PATH_QUIZ_QUESTION, PATH_QUIZ_RESULT, PATH_QUIZ_VICTORY, PATH_QUIZ_VICTORY_FILL_DATA } from "./Constants"
import Favourites from "./pages/favourites/Favourites"
import Credits from "./pages/home/Credits"
import Home from "./pages/home/Home"
import Artwork3DModel from "./pages/model3d/Model3D"
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy"
import Answer from "./pages/quiz/Answer"
import Clue from "./pages/quiz/Clue"
import ClueResult from "./pages/quiz/ClueResult"
import Victory from "./pages/quiz/Victory"
import VictoryFillData from "./pages/quiz/VictoryFillData"

function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Routes>
      <Route path={PATH_HOME} element={<Home />} />
      <Route path={PATH_CREDITS} element={<Credits />} />
      <Route path={PATH_FAVOURITES} element={<Favourites />} />
      <Route path={PATH_PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={PATH_MODEL3D} element={<Artwork3DModel />} />
      <Route path={PATH_QUIZ_QUESTION} element={<Clue />} />
      <Route path={PATH_QUIZ_ANSWER} element={<Answer />} />
      <Route path={PATH_QUIZ_RESULT} element={<ClueResult />} />
      <Route path={PATH_QUIZ_VICTORY} element={<Victory />} />
      <Route path={PATH_QUIZ_VICTORY_FILL_DATA} element={<VictoryFillData />} />
    </Routes>
  )
}

export default App
