import { createStyles, makeStyles } from "@material-ui/core/styles"
import { QuizAnswer } from "../../model/Model"
import { Colors } from "../Colors"

const useStyles = makeStyles(
  createStyles({
    radioButtonContainer: {
      backgroundColor: Colors.white,
      borderRadius: "3px",
      width: "75vw",
      margin: "0 auto"
    },
    radioButtonLabel: {
      height: "34px",
      marginLeft: "5px",
      color: Colors.gray,
      fontSize: "14px",
      fontFamily: "Raleway-Medium",
      display: "flex",
      alignItems: "center"
    },
    radioInput: {
      accentColor: Colors.green, 
      borderColor: Colors.blue
    }
  })
)

interface PropsType {
    onSelect: (answer: QuizAnswer) => void,
    answer: QuizAnswer,
    selectedRadioButton: number
}

const RadioButton = (props: PropsType) => {
  const classes = useStyles()
    
  return (
    <div className={classes.radioButtonContainer} 
      style={{
        backgroundColor: props.selectedRadioButton === props.answer.pk ? Colors.lightGreen : Colors.white
      }}>
      <label className={classes.radioButtonLabel}>
        <input type="radio" value={props.answer.pk} checked={props.selectedRadioButton === props.answer.pk}
          onClick={() => { props.onSelect(props.answer) }} className={classes.radioInput} />
        {`${props.answer.artworkName} (${props.answer.itineraryName})`}
      </label>
    </div>
  )
}

export default RadioButton