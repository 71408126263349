import { createStyles, makeStyles } from "@material-ui/core/styles"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import congrats from "../../assets/congrats.svg"
import ohno from "../../assets/ohno.svg"
import oops from "../../assets/oops.svg"
import { Colors } from "../../common/Colors"
import Topbar from "../../common/components/Topbar"
import { formatString } from "../../common/Utils"
import { PATH_QUIZ_VICTORY_FILL_DATA } from "../../Constants"
import { UserQuiz, VisitStatus } from "../../model/Model"
import { getAnswer } from "../../services/QuizService"
import { strings } from "../../translations"
import AnswerListView from "./AnswerListView"

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    title: {
      marginTop: "30px",
      color: Colors.green,
      fontSize: "22px",
      fontFamily: "Raleway-Bold"
    },
    subtitle: {
      marginTop: "0px",
      width: "90%",
      textAlign: "center",
      color: Colors.white,
      fontSize: "16px",
      fontFamily: "Poppins-Bold"
    },
    description: {
      marginTop: "30px",
      marginBottom: "30px",
      color: Colors.white,
      fontSize: "16px",
      fontFamily: "Raleway-Medium",
      width: "95%",
      textAlign: "center",
      whiteSpace: "pre-wrap"
    },
    answer: {
      marginTop: "-15px",
      color: Colors.white,
      fontSize: "32px",
      fontFamily: "Raleway-Bold",
      width: "95%",
      textAlign: "center"
    },
    answerDescription: {
      marginTop: "-25px",
      color: Colors.white,
      fontSize: "16px",
      fontFamily: "Raleway-Medium",
      width: "100%",
      textAlign: "center"
    },
  })
)

const PARAM_NAME = "artwork_pk"

const ClueResult = () => {
  const classes = useStyles()
  const [quiz, setQuiz] = useState<UserQuiz>()
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0)
  const [question, setQuestion] = useState("")
  const [answer, setAnswer] = useState("")
  const [correct, setCorrect] = useState(false)

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {

    const fetchAnswer = async () => {
      const artworkId = searchParams.get(PARAM_NAME)

      getAnswer(Number(artworkId), (userQuiz: UserQuiz) => {
        setQuiz(userQuiz)
        setLastAnswer(userQuiz)
      })
    }
    fetchAnswer()
  }, [])

  function setLastAnswer(quiz: UserQuiz) {
    const currentAnswer = quiz.userQuizAnswers[quiz.currentQuestionNumber - 1]
    if (quiz.currentQuestionNumber === quiz.userQuizAnswers.length && currentAnswer.answeredCorrectly) {
      navigate(PATH_QUIZ_VICTORY_FILL_DATA)
    } else {
      const isCorrect = !currentAnswer.visited || currentAnswer.visited && currentAnswer.answeredCorrectly
      setCorrect(isCorrect)
      setCurrentQuestionNumber(quiz.currentQuestionNumber)
      setQuestion(quiz.questionText)
      const answerIndex = isCorrect ? quiz.currentQuestionNumber - 2 : quiz.currentQuestionNumber - 1
      setAnswer(quiz.userQuizAnswers[answerIndex].answerText)
    }
  }

  return (
    <div className={classes.container}>
      <Topbar />
      { quiz && 
              <div className={classes.content}>
                {quiz.visitStatusCode === VisitStatus.Success ?
                  <div className={classes.content} >
                    <p className={classes.description}>{question}</p>
                    {correct ? <img src={congrats} /> : <img src={ohno} />}
                    <p className={classes.title}>{correct ? strings.congrats : strings.ohno}</p>
                    <p className={classes.answer}>{answer}</p>
                    <p className={classes.answerDescription}>{correct ? strings.rightAnswer : strings.wrongAnswer}</p>
                  </div>
                  : 
                  <div className={classes.content} >
                    <img src={oops} style={{ marginTop: "30px" }}/>
                    <p className={classes.title}>{strings.oops}</p>
                    <p className={classes.subtitle}>{
                      quiz.visitStatusCode === VisitStatus.WrongHotspot ?
                        formatString(strings.wrongHotspot, quiz.currentQuestionNumber.toString())
                        : 
                        strings.skippedQuestion }</p>
                  </div>
                }
                <AnswerListView userQuizAnswers={quiz.userQuizAnswers} currentQuestionNumber={currentQuestionNumber} />
              </div>
      }
    </div>
  )
}

export default ClueResult