import { createStyles, makeStyles } from "@material-ui/core/styles"
import { useNavigate } from "react-router-dom"
import homeButton from "../../assets/home_button.svg"
import homeLogo from "../../assets/home_logo.svg"
import { PATH_HOME } from "../../Constants"
import { Colors } from "../Colors"

const useStyles = makeStyles(
  createStyles({
    header: {
      position: "relative",
      display: "flex",
      height: "71px",
      justifyContent: "center",
      alignItems: "center"
    },
    home: {
      position: "absolute",
      display: "flex",
      left: "25px",
    },
    title: {
      align: "center",
      color: "white",
      fontSize: "22px",
      fontFamily: "Raleway-Bold"
    },
    logo: {
      align: "center"
    },
    divider: {
      height: "1px",
      background: Colors.darkBlue
    }
  })
)

interface PropsType {
    title?: string
    homeButtonHidden?: boolean
}

const Topbar = (props: PropsType) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div>
      <div className={classes.header}>
        {(!props.homeButtonHidden) && <img className={classes.home} src={homeButton} onClick={() => { navigate(PATH_HOME) }} /> }
        { !props.title ? 
          <img className={classes.logo} src={homeLogo} />
          : 
          <p className={classes.title}>{props.title}</p>
        }
      </div>
      <div className={classes.divider} />
    </div>

  )
}

export default Topbar