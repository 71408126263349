export const Colors = {
  blue: "#2573AE",
  softBlue: "#7F9FB8",
  softBlue2: "#BAD7ED",
  darkBlue: "#21669B",
  blue_a10: "#3B7FB3",
  gray_a50: "#807A7A7A",
  gray: "#7A7A7A",
  green: "#8EBD21",
  darkGreen: "#6D9511",
  lightGreen: "#e4f5e6",
  white: "#FFFFFF",
  black: "#1E1E1E"
}