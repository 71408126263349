import { Button, CircularProgress } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import victory from "../../assets/victory.svg"
import { Colors } from "../../common/Colors"
import TextField from "../../common/components/TextField"
import Topbar from "../../common/components/Topbar"
import { isEmailValid, isNotEmpty } from "../../common/Utils"
import { PATH_PRIVACY_POLICY, PATH_QUIZ_VICTORY } from "../../Constants"
import { TourEntrance, User } from "../../model/Model"
import { getCurrentUser, saveUser } from "../../services/UserService"
import { strings } from "../../translations"

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    title: {
      marginTop: "40px",
      color: Colors.green,
      fontSize: "20px",
      fontFamily: "Poppins-Bold"
    },
    subtitle: {
      marginTop: "0px",
      width: "50%",
      textAlign: "center",
      color: Colors.white,
      fontSize: "32px",
      fontFamily: "Raleway-Bold"
    },
    description: {
      marginTop: "0px",
      marginBottom: "30px",
      color: Colors.softBlue2,
      fontSize: "16px",
      fontFamily: "Raleway-Medium",
      width: "50%",
      textAlign: "center"
    },
    checkboxContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "68%",
      marginTop: "10px"
    },
    checkbox: {
      borderColor: Colors.green,
      accentColor: Colors.green
    },
    checkboxText: {
      fontSize: "10px",
      color: Colors.white,
      fontFamily: "Raleway-Medium"
    },
    checkboxLinkText: {
      fontSize: "10px",
      color: Colors.green,
      marginLeft: "3px",
      fontFamily: "Raleway-Bold"
    },
    loaderContainer: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: Colors.gray_a50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    loader: {
      "color": Colors.green,
    },
    collectButton: {
      width: "65%",
      height: "35px",
      textTransform: "uppercase",
      textSize: "14px",
      color: Colors.white,
      background: Colors.green,
      marginTop: "20px",
      marginBottom: "50px",
      fontFamily: "Raleway-Bold",
      "&:hover": {
        background: Colors.green,
      },
      "&:focus": {
        background: Colors.green,
      },
    },


  })
)

const VictoryFillData = () => {
  const classes = useStyles()

  const [user, setUser] = useState<User>({ name: "", email: "", tourEntrance: TourEntrance.Empty })
  const [modifiedName, setModifiedName] = useState("")
  const [modifiedEmail, setModifiedEmail] = useState("")
  const [checked, setChecked] = useState(false)
  const [collectLoading, setCollectLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    getCurrentUser(TourEntrance.Empty, (user: User) => {
      if (isNotEmpty(user.name) && isNotEmpty(user.email)) {
        navigate(PATH_QUIZ_VICTORY, { state: user })
      } else {
        setUser(user)
        setModifiedName(user.name)
        setModifiedEmail(user.email)
        setChecked(true)
        setLoading(true)
      }
    })
  }, [])

  function handleNameChanged(value: string) {
    setModifiedName(value)
  }

  function handleEmailChanged(value: string) {
    setModifiedEmail(value)
  }

  function handleCollectClick() {
    if (checked && modifiedName && modifiedEmail && isEmailValid(modifiedEmail)) {
      saveCurrentUser()
    } else {
      if (checked) {
        if (modifiedEmail && !isEmailValid(modifiedEmail)) {
          alert(strings.invalidEmailMessage)
        } else {
          alert(strings.invalidFieldsMessage)
        }
      } else {
        alert(strings.unselectedCheckboxMessage)
      }
    }
  }

  function saveCurrentUser() {
    setCollectLoading(true)
    const modifiedUser = { ...user, name: modifiedName, email: modifiedEmail }

    saveUser(modifiedUser, (successfullySaved: boolean) => {
      if (successfullySaved) {
        setUser(modifiedUser)
        navigate(PATH_QUIZ_VICTORY, { state: modifiedUser })
      } else {
        alert(strings.saveError)
      }
      setCollectLoading(false)
    })
  }


  return (
    <div className={classes.container}>
      <Topbar />
      {loading  && <div className={classes.content}>
        <img src={victory} style={{ marginTop: "80px" }} />
        <p className={classes.title}>{strings.yay}</p>
        <p className={classes.subtitle}>{strings.treasureFound}</p>
        <p className={classes.description}>{strings.fillMissingFields}</p>    

        <TextField onChange={handleNameChanged} placeholder={strings.fullName} value={modifiedName} type="text" />
        <TextField onChange={handleEmailChanged} placeholder={strings.email} value={modifiedEmail} type="email" />

        <div className={classes.checkboxContainer}>
          <input type="checkbox" className={classes.checkbox} checked={checked} onChange={() => { setChecked(!checked) }} />

          <p className={classes.checkboxText}>{strings.termsAccept}</p>
          <p className={classes.checkboxLinkText} onClick={() => navigate(PATH_PRIVACY_POLICY)}>{strings.privacyPolicy}</p>
        </div>
     
        <Button variant="contained" classes={{ root: classes.collectButton }} disabled={collectLoading} onClick={handleCollectClick}>{strings.collectPrize}</Button>

        {collectLoading &&
                  <div className={classes.loaderContainer}>
                    <CircularProgress classes={{ root: classes.loader }} />
                  </div>
        }

      </div>
      }
    </div>
  )
}

export default VictoryFillData