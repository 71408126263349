import axios, { AxiosError, AxiosResponse } from "axios"
import { TourEntrance, User } from "../model/Model"
import { API_URL, STATUS_CODE_OK } from "./Constants"

const CURRENT_USER_ADDRESS = "/user/current"
const SAVE_USER_ADDRESS = "/user/save"

export function getCurrentUser(tourEntrance: TourEntrance, callback: (user: User) => void) {
  axios({
    method: "GET",
    url: API_URL + CURRENT_USER_ADDRESS,
    params: {
      tourEntrance: tourEntrance
    }
  }).then((response: AxiosResponse) => {
    callback(response.data)
  }).catch((error: AxiosError) => {
    console.log(error)
  })
}

export function saveUser(user: User, callback: (successfullySaved: boolean) => void) {
  axios({
    method: "POST",
    url: API_URL + SAVE_USER_ADDRESS,
    data: user
  }).then((response: AxiosResponse) => {
    callback(response.status === STATUS_CODE_OK)
  }).catch((error: AxiosError) => {
    console.log(error)
  })
}