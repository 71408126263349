import axios, { AxiosError, AxiosResponse } from "axios"
import { Itinerary } from "../model/Model"
import { API_URL } from "./Constants"

const ITINERARY_ADDRESS = "/itinerary/all"

export function getItineraries(callback: (itineraries: Itinerary[]) => void) {
  axios({
    method: "GET",
    url: API_URL + ITINERARY_ADDRESS,
  }).then((response: AxiosResponse) => {
    callback(response.data)
  }).catch((error: AxiosError) => {
    console.log(error)
  })
}