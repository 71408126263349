import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import cancelButton from "../../assets/cancel.svg"
import sendEmailButton from "../../assets/send_email.svg"
import success from "../../assets/success.svg"
import { Colors } from "../../common/Colors"
import FavouriteListView from "../../common/components/FavouriteListView"
import TextField from "../../common/components/TextField"
import Topbar from "../../common/components/Topbar"
import { formatString, isEmailValid, isNotEmpty } from "../../common/Utils"
import { PATH_PRIVACY_POLICY } from "../../Constants"
import { Favourite, TourEntrance, User } from "../../model/Model"
import { getFavourites, removeFavourite, saveFavourite, sendEmailList } from "../../services/FavouriteService"
import { getCurrentUser, saveUser } from "../../services/UserService"
import { strings } from "../../translations"

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue
    },
    userProfile: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "90px",
      width: "100vw"
    },
    userContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "85%",
      paddingLeft: "10%"
    },
    helloText: {
      fontSize: "16px",
      color: Colors.white,
      textAlign: "center",
      marginTop: "0px",
      fontFamily: "Raleway-Medium"
    },
    modifyText: {
      fontSize: "10px",
      color: Colors.green,
      textAlign: "center",
      marginTop: "-13px",
      fontFamily: "Raleway-Bold"
    },
    sendEmailContainer: {
      width: "15%"
    },
    modifyProfileContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px"
    },
    checkboxContainer: {
      display: "flex",
      width: "68%",
      flexDirection: "row",
      alignItems: "center"
    },
    checkbox: {
      borderColor: Colors.green,
      accentColor: Colors.green,
      marginTop: "4px"
    },
    checkboxText: {
      fontSize: "10px",
      color: Colors.white,
      fontFamily: "Raleway-Medium"
    },
    checkboxLinkText: {
      fontSize: "10px",
      color: Colors.green,
      marginLeft: "3px",
      fontFamily: "Raleway-Bold"
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "50px",
      marginBottom: "20px"
    },
    cancelButton: {
      width: "90px",
      height: "35px",
      textTransform: "uppercase",
      textSize: "14px",
      color: Colors.white,
      background: Colors.softBlue,
      marginRight: "14px",
      fontFamily: "Raleway-Bold"
    },
    saveButton: {
      width: "90px",
      height: "35px",
      textTransform: "uppercase",
      textSize: "14px",
      color: Colors.white,
      background: Colors.green,
      fontFamily: "Raleway-Bold",
      "&:hover": {
        background: Colors.green,
      },
      "&:focus": {
        background: Colors.green,
      },
    },
    sendListContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
      background: Colors.darkBlue,
    },
    sendButton: {
      width: "58px",
      height: "35px",
      textTransform: "uppercase",
      textSize: "14px",
      color: Colors.white,
      background: Colors.green,
      marginLeft: "10px",
      marginRight: "10px",
      fontFamily: "Raleway-Bold",
      "&:hover:focus": {
        background: Colors.green,
      }
    },
    favouriteListContainer: {
      display: "flex",
      justifyContent: "center",
      height: "100vh"
    },
    noFavouriteText: {
      fontSize: "16px",
      color: Colors.white,
      fontFamily: "Raleway-Medium",
      width: "100%",
      textAlign: "center",
      marginTop: "50%"
    },
    emailSuccessContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
      background: Colors.green
    },
    emailSuccessText: {
      fontSize: "17px",
      color: Colors.white,
      textAlign: "center",
      marginLeft: "20px",
      fontFamily: "Poppins-SemiBold"
    },
    loaderContainer: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: Colors.gray_a50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    loader: {
      "color": Colors.green,
    }
  })
)

const ARTWORK_PARAM = "artwork_pk"
const EMAIL_SUCCESS_TIMEOUT = 3000

const Favourites = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [user, setUser] = useState<User>({ name: "", email: "", tourEntrance: TourEntrance.Virtual })
  const [favourites, setFavourites] = useState<Favourite[]>([])
  const [favouritesFound, setFavouritesFound] = useState<boolean>(false)

  const [modifyProfile, setModifyProfile] = useState(false)
  const [modifiedName, setModifiedName] = useState("")
  const [modifiedEmail, setModifiedEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)

  const [sendList, setSendList] = useState(false)
  const [emailList, setEmailList] = useState("")
  const [emailSuccess, setEmailSuccess] = useState(false)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const fetchCurrentUser = async () => {
      getCurrentUser(TourEntrance.Empty, (user: User) => {
        setUser(user)
        setEmailList(user.email)
        setModifiedName(user.name)
        setModifiedEmail(user.email)
        setChecked(isNotEmpty(user.name) || isNotEmpty(user.email))

        const artworkParam = searchParams.get(ARTWORK_PARAM)
        if (artworkParam === null) {
          fetchFavourites()
        } else {
          addAndFetchFavourites(artworkParam)
        }
      })
    }
    const fetchFavourites = async () => {
      getFavourites((favourites: Array<Favourite>) => {
        setFavourites(favourites)
        setFavouritesFound(true)
      })
    }
    const addAndFetchFavourites = async (artworkPk: string) => {
      saveFavourite(Number(artworkPk), (favourites: Array<Favourite>) => {
        setFavourites(favourites)
        setFavouritesFound(true)
      })
    }

    fetchCurrentUser()
    
  }, [])

  function handleNameChanged(value: string) {
    setModifiedName(value)
  }

  function handleEmailChanged(value: string) {
    setModifiedEmail(value)
  }

  function handleSendListEmailChanged(value: string) {
    setEmailList(value)
  }

  function handleSaveClick() {
    if (!modifiedName && !modifiedEmail) {
      saveCurrentUser()
    } else {
      if (checked) {
        if (!isEmailValid(modifiedEmail)) {
          alert(strings.invalidEmailMessage)
        } else {
          saveCurrentUser()
        }
      } else {
        alert(strings.unselectedCheckboxMessage)
      }
    }
  }

  function handleCancelClick() {
    setModifyProfile(false)
    setModifiedName(user.name)
    setModifiedEmail(user.email)
    setChecked(isNotEmpty(user.name) || isNotEmpty(user.email))
  }

  function handleSendClick() {
    if (emailList === null || !isEmailValid(emailList)) {
      alert(strings.invalidEmailMessage)
    } else if (favourites.length === 0) {
      alert(strings.noFavourites)
    } else {
      setLoading(true)
      sendEmailList(emailList, (emailSuccessfullySent: boolean) => {
        setLoading(false)
        setEmailSuccess(emailSuccessfullySent)

        if (emailSuccessfullySent) {
          setEmailSuccess(true)
          setTimeout(() => {
            setEmailSuccess(false)
          }, EMAIL_SUCCESS_TIMEOUT)
        }
      })
    }
  }

  function saveCurrentUser() {
    setLoading(true)
    const modifiedUser = { ...user, name: modifiedName, email: modifiedEmail }

    saveUser(modifiedUser, (successfullySaved: boolean) => {
      setModifyProfile(false)
      if (successfullySaved) {
        setUser(modifiedUser)
        setEmailList(modifiedUser.email)
      } else {
        alert(strings.saveError)
      }
      setLoading(false)
    })
  }

  function onArtworkRemove(favourite: Favourite) {
    if (confirm(strings.removeFavouriteMessage)) {
      removeFavourite(favourite.pk, (favourites: Array<Favourite>) => {
        setFavourites(favourites)
      })
    }
  }

  return (
    <div className={classes.container}>
      <Topbar title={strings.favourites} />

      {!modifyProfile && !sendList ?
        <div className={classes.userProfile}>
          <div className={classes.userContent}>
            <p className={classes.helloText}>{formatString(strings.helloUser, isNotEmpty(user.name) ? user.name : strings.anonymousUser)}</p>
            <p className={classes.modifyText} onClick={() => { setModifyProfile(true) }}>{strings.modifyProfile}</p>
          </div>
          <div className={classes.sendEmailContainer}>
            <img src={sendEmailButton} onClick={() => { setSendList(true) }} />
          </div>
        </div>
        :
        modifyProfile ?
          <div style={{ background: Colors.darkBlue }}>
            <div className={classes.modifyProfileContainer}>
              <TextField onChange={handleNameChanged} placeholder={strings.fullName} value={modifiedName} type="text" />
              <TextField onChange={handleEmailChanged} placeholder={strings.email} value={modifiedEmail} type="email" />
              <div className={classes.checkboxContainer}>
                <input type="checkbox" className={classes.checkbox} checked={checked} onChange={() => { setChecked(!checked) }} />

                <p className={classes.checkboxText}>{strings.termsAccept}</p>
                <p className={classes.checkboxLinkText} onClick={() => navigate(PATH_PRIVACY_POLICY)}>{strings.privacyPolicy}</p>
              </div>
            </div>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" classes={{ root: classes.cancelButton }} onClick={handleCancelClick}>{strings.cancel}</Button>
              <Button variant="contained" classes={{ root: classes.saveButton }} disabled={loading} onClick={handleSaveClick}>{strings.save}</Button>
            </div>
          </div>
          :
          !emailSuccess ?
            <div className={classes.sendListContainer} >
              <TextField onChange={handleSendListEmailChanged} placeholder={strings.sendPlaceholder} value={emailList} type="email"/>
              <Button variant="contained" classes={{ root: classes.sendButton }} disabled={loading} onClick={handleSendClick}>{strings.send}</Button>
              <img src={cancelButton} onClick={() => {
                setSendList(false)
                setEmailList(user.email)
              }} />
            </div>
            :
            <div className={classes.emailSuccessContainer} >
              <img src={success} />
              <p className={classes.emailSuccessText}>{strings.emailSuccessMessage}</p>
            </div>
      }

      {favouritesFound && favourites.length === 0 &&
        <p className={classes.noFavouriteText}>{strings.noFavourites}</p>
      }
      
      <div className={classes.favouriteListContainer}>
        <FavouriteListView favourites={favourites} onRemove={onArtworkRemove} />
      </div>

      {loading &&
        <div className={classes.loaderContainer}>
          <CircularProgress classes={{ root: classes.loader }} />
        </div>
      }
      
    </div>
  )
}
export default Favourites