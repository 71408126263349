import { createStyles, makeStyles } from "@material-ui/core/styles"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import treasure from "../../assets/treasure.svg"
import { Colors } from "../../common/Colors"
import RadioButton from "../../common/components/RadioButton"
import Topbar from "../../common/components/Topbar"
import { formatString } from "../../common/Utils"
import { PATH_QUIZ_ANSWER, PATH_QUIZ_VICTORY_FILL_DATA } from "../../Constants"
import { QuizAnswer, QuizQuestion } from "../../model/Model"
import { getQuizQuestion, saveAnswer } from "../../services/QuizService"
import { strings } from "../../translations"

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    title: {
      marginTop: "30px",
      color: Colors.green,
      fontSize: "22px",
      fontFamily: "Raleway-Bold"
    },
    description: {
      marginTop: "0px",
      color: Colors.white,
      fontSize: "16px",
      fontFamily: "Raleway-Medium",
      width: "90%",
      textAlign: "center"
    },
    questionContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "5px",
      width: "90%",
      backgroundColor: Colors.white,
      marginTop: "25px",
      marginBottom: "50px",
      paddingBottom: "25px"
    },
    questionNumberContainer: {
      height: "50px",
      width: "50px",
      background: Colors.green,
      borderRadius: "50%",
      zIndex: 9,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "-25px"
    },
    questionNumber: {
      color: Colors.white,
      fontSize: "32px",
      fontFamily: "Raleway-Bold"
    },
    questionNumberTitle: {
      marginTop: "20px",
      color: Colors.green,
      fontSize: "22px",
      fontFamily: "Raleway-Bold",
      width: "100%",
      textAlign: "center"
    },
    question: {
      color: Colors.black,
      fontSize: "15px",
      fontFamily: "Raleway-Bold",
      width: "90%",
      margin: "0 auto",
      textAlign: "center",
      whiteSpace: "pre-wrap",
      marginBottom: "10px"
    }
  })
)

const FIRST_QUESTION_NUMBER = 1

const Clue = () => {
  const classes = useStyles()
  const [quizQuestion, setQuizQuestion] = useState<QuizQuestion>({question: "", answers: [], number: 0, quizFinished: false })
  const [selectedRadioButton, setSelectedRadioButton] = useState(-1)
  const [fetchFinished, setFetchFinished] = useState(false)

  const [number, setNumber] = useState("")
  const navigate = useNavigate()
  
  useEffect(() => {

    const fetchQuiz = async () => {
      getQuizQuestion((quizQuestion: QuizQuestion) => {
        if(quizQuestion.quizFinished) {
          navigate(PATH_QUIZ_VICTORY_FILL_DATA)
        } else {
          setQuizQuestion(quizQuestion)
          setNumber(quizQuestion.number.toString())
        }
        const selectedAnswer = quizQuestion.answers.filter(ans => ans.selected === true)
        if(selectedAnswer.length > 0) {
          setSelectedRadioButton(selectedAnswer[0].pk)
        }
        setFetchFinished(true)
      })
    }
    fetchQuiz()
  }, [])

  function onRadioButtonSelected(quizAnswer: QuizAnswer) {
    setSelectedRadioButton(quizAnswer.pk)
    saveAnswer(quizAnswer.pk, (successfullySaved: boolean) => {
      if(successfullySaved) {
        navigate(PATH_QUIZ_ANSWER, { state: quizAnswer })
      } else {
        alert(strings.savingAnswerFailureMessage)
      }
    })
  }

  return (
    <div className={classes.container}>
      <Topbar />

      { fetchFinished && <div className={classes.content}>
        <p className={classes.title}>{strings.quizTitle}</p>
        <img src={treasure} style={{ marginBottom: "30px" }}/>
        {quizQuestion.number === FIRST_QUESTION_NUMBER && <p className={classes.description}>{strings.quizShortDescription} </p> }

        <div className={classes.questionContainer}>
          <div className={classes.questionNumberContainer}>
            <p className={classes.questionNumber}>{number}</p>
          </div>
          {quizQuestion !== null && 
            <div>
              <p className={classes.questionNumberTitle}>{formatString(strings.clueNumber, number)}</p>
              <p className={classes.question}>{quizQuestion.question}</p>

              {quizQuestion.answers.map((answer: QuizAnswer) => (
                <RadioButton key={answer.pk} answer={answer} selectedRadioButton={selectedRadioButton} onSelect={onRadioButtonSelected} />
              ))}

            </div> 
          }
        </div>
      </div>
      }
    </div>
        
  )
}

export default Clue