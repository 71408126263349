import Button from "@material-ui/core/Button"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { useNavigate } from "react-router-dom"
import { Colors } from "../../common/Colors"
import { formatString, isNotEmpty } from "../../common/Utils"
import { PATH_QUIZ_QUESTION } from "../../Constants"
import { UserQuizAnswer } from "../../model/Model"
import { strings } from "../../translations"

const useStyles = makeStyles(
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "5px",
      background: Colors.white,
      height: "68px",
      width: "100%",
      marginBottom: "10px"
    },
    numberContainer: {
      height: "30px",
      width: "30px",
      borderRadius: "50%",
      zIndex: 9,
      display: "flex",
      justifyContent: "center",
      marginLeft: "15px"
    },
    number: {
      fontSize: "19px",
      fontFamily: "Raleway-Bold",
      lineHeight: "30px",
      textAlign: "center",
      display: "inline-block",
      verticalAlign: "middle",
    },
    value: {
      color: Colors.white,
      fontSize: "17px",
      fontFamily: "Raleway-Bold",
      marginLeft: "25px",
      width: "50%"
    },
    answerButton: {
      height: "21px",
      fontSize: "12px",
      textTransform: "lowercase",
      color: Colors.white,
      background: "#3B7FB3",
      paddingLeft: "10px",
      paddingRight: "10px",
      fontFamily: "Poppins-Medium",
      minWidth: "150px",
      maxWidth: "200px",
      "&:hover:focus": {
        background: Colors.white
      },
      "& .$MuiButton-label": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    },
    viewButton: {
      height: "21px",
      textSize: "12px",
      textTransform: "lowercase",
      color: Colors.green,
      background: Colors.white,
      minWidth: "150px",
      fontFamily: "Poppins-Medium",
      "&:hover:focus": {
        background: Colors.white
      }
    }, 
    tryAgainButton: {
      height: "21px",
      textSize: "12px",
      textTransform: "lowercase",
      color: Colors.white,
      background: Colors.green,
      paddingLeft: "10px",
      paddingRight: "10px",
      fontFamily: "Poppins-Medium",
      whiteSpace: "nowrap",
      minWidth: "150px",
      maxWidth: "200px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "&:hover:focus": {
        background: Colors.green
      }
    },

  })
)

interface PropsType {
    answer: UserQuizAnswer
    isCurrent: boolean
}

const AnswerItem = (props: PropsType) => {
  const classes = useStyles()

  const navigate = useNavigate()

  const { questionNumber, visited, answeredCorrectly, answerText} = props.answer
  let value = answerText
  let valueColor = Colors.white
  let backgroundColor = Colors.blue_a10
  let buttonText = ""
  let numberBackgroundColor = Colors.green
  let numberTextColor = Colors.white

  if (props.isCurrent && ((!isNotEmpty(answerText) && !visited )|| visited)) {
    backgroundColor = Colors.white
    value = formatString(strings.clueNumber, questionNumber.toString())
    buttonText = strings.tryAgain
    numberBackgroundColor = Colors.green
    numberTextColor = Colors.white
    valueColor = Colors.green
  }
  else if (visited) {
    if (answeredCorrectly) {
      value = strings.done
      backgroundColor = Colors.darkBlue
      buttonText = answerText
      numberBackgroundColor = Colors.softBlue
      numberTextColor = Colors.darkBlue
    } else {
      backgroundColor = Colors.white
      value = formatString(strings.clueNumber, questionNumber.toString())
    }
  } else {
    if (isNotEmpty(answerText)) {
      backgroundColor = Colors.softBlue
      value = strings.waiting
      buttonText = strings.viewQuestion
      numberBackgroundColor = Colors.white
      numberTextColor = Colors.softBlue
    } else {
      backgroundColor = Colors.blue_a10
      value = formatString(strings.clueNumber, questionNumber.toString())
    }
  }
   
  return (

    <div className={classes.root} style={{ backgroundColor: backgroundColor, color: numberTextColor}}
      onClick={() => { if (props.isCurrent || !visited && isNotEmpty(answerText)) navigate(PATH_QUIZ_QUESTION) }}>
      <div style={{ width: "10%"}}>
        <div className={classes.numberContainer} style={{ backgroundColor: numberBackgroundColor}}>
          <span className={classes.number}>{questionNumber}</span>
        </div>

      </div>

      <p className={classes.value} style={{ color: valueColor }}>{value}</p>

      {(!visited && isNotEmpty(answerText) ||  visited) &&
        <div style={{ marginRight: "10px"}}>
          <Button variant="contained" classes={{ root: visited ? 
            answeredCorrectly ? classes.answerButton : classes.tryAgainButton
            : classes.viewButton }}>{buttonText}</Button>
        </div>
      }
    </div>
  )
}

export default AnswerItem