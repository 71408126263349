import { createStyles, makeStyles } from "@material-ui/core/styles"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Colors } from "../../common/Colors"
import ItineraryButton from "../../common/components/ItineraryButton"
import Topbar from "../../common/components/Topbar"
import { formatString, openUrl } from "../../common/Utils"
import { QuizAnswer, UserQuizAnswer } from "../../model/Model"
import { getUserQuizState } from "../../services/QuizService"
import { strings } from "../../translations"
import AnswerListView from "./AnswerListView"

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    title: {
      marginTop: "30px",
      color: Colors.white,
      fontSize: "16px",
      fontFamily: "Raleway-Medium"
    },
    instructionsContainer: {
      width: "90%",
      marginTop: "10px",
      color: Colors.white,
      fontSize: "16px",
      fontFamily: "Raleway-Medium",
      textAlign: "center"
    },
    instructionsLink: {
      color: Colors.green,
      fontFamily: "Raleway-Bold",
    },
    response: {
      color: Colors.white,
      fontSize: "32px",
      fontFamily: "Raleway-Bold",
      width: "80%",
      textAlign: "center",
      marginTop: "15px"
    },
    quizContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "20px"
    },
    divider: {
      height: "1px",
      background: Colors.darkBlue,
      width: "90%",
      marginTop: "15px"
    }
  })
)

const Answer = () => {
  const classes = useStyles()

  const location = useLocation()

  const [quiz, setQuiz] = useState<UserQuizAnswer[]>([])
  const [quizAnswer, setQuizAnswer] = useState<QuizAnswer>({
    pk: 0,
    artworkName: "",
    itineraryName: "",
    itineraryUrl: "",
    selected: false
  })

  useEffect(() => {
    const fetchUserQuizState = async () => {
      getUserQuizState((userQuizAnswers: UserQuizAnswer[]) => {
        setQuiz(userQuizAnswers)
      })
    }

    if(location.state) {
      setQuizAnswer(location.state as QuizAnswer)
    }
    fetchUserQuizState()
  }, [])

  return (
    <div className={classes.container}>
      <Topbar />

      { quizAnswer.artworkName && 
          <div className={classes.content}>
            <p className={classes.title}>{strings.yourAnswer}</p>

            <p className={classes.response}>{quizAnswer.artworkName}</p>
        
            <p className={classes.instructionsContainer}>
              <span>{formatString(strings.answerInstruction1, quizAnswer.artworkName)}</span>
              <span className={classes.instructionsLink} onClick={() => openUrl(quizAnswer.itineraryUrl)}>{quizAnswer.itineraryName}</span>
              <span>{strings.answerInstruction2}</span>
            </p>

            <div style={{width: "90%"}}> 
              <ItineraryButton itinerary={{ name: quizAnswer.itineraryName, url: quizAnswer.itineraryUrl }}
                onClick={() => openUrl(quizAnswer.itineraryUrl)} />
            </div>
            <div className={classes.divider}/>
          </div>
      }
      <div className={classes.quizContainer}>
        <AnswerListView userQuizAnswers={quiz} />
      </div>

    </div>
  )
}

export default Answer