import List from "@material-ui/core/List"
import { UserQuizAnswer } from "../../model/Model"
import AnswerItem from "./AnswerItem"

interface PropsType {
    userQuizAnswers: UserQuizAnswer[],
    currentQuestionNumber?: number
}

export default function AnswerListView(props: PropsType) {
  return (
    <List style={{ width: "90%" }}>
      {props.userQuizAnswers.map((userQuizAnswer: UserQuizAnswer) => (
        <AnswerItem key={userQuizAnswer.questionNumber} answer={userQuizAnswer} 
          isCurrent={props.currentQuestionNumber === userQuizAnswer.questionNumber}/>
      ))}
    </List>
  )
}