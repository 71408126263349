import List from "@material-ui/core/List"
import { Itinerary } from "../../model/Model"
import ItineraryButton from "./ItineraryButton"

interface PropsType {
    itineraries: Itinerary[],
    onClick: (itinerary: Itinerary) => void
}

export default function ItineraryListView(props: PropsType) {
  return (
    <List style={{ width: "90%" }}>
      {props.itineraries.map((itinerary: Itinerary) => (
        <ItineraryButton key={itinerary.name} itinerary={itinerary} onClick={props.onClick}/>
      ))}
    </List>
  )
}