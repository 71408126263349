export interface Itinerary {
    name: string
    url: string
}

export interface User {
    name: string
    email: string,
    tourEntrance: TourEntrance
}

export enum TourEntrance {
    Empty = 0,
    Virtual = 1,
    Real = 2
}

export interface Favourite {
    pk: number
    title: string,
    itineraryName: string
    url: string
}

export interface ModelData {
    title: string,
    description: string,
    filename: string,
    position: number[],
    scale: number[]
}

export interface QuizQuestion {
    question: string
    answers: QuizAnswer[],
    number: number,
    quizFinished: boolean
}

export interface QuizAnswer {
    pk: number
    artworkName: string
    itineraryName: string
    itineraryUrl: string
    selected: boolean
}

export interface UserQuizAnswer {
    questionNumber: number
    visited: boolean
    answeredCorrectly: boolean
    answerText: string
}

export interface UserQuiz {
    questionText: string
    currentQuestionNumber: number
    userQuizAnswers: UserQuizAnswer[]
    visitStatusCode: VisitStatus
}

export enum VisitStatus {
    Success = 0,
    WrongHotspot = 1,
    SkippedQuestion = 2
}