import axios, { AxiosError, AxiosResponse } from "axios"
import { QuizQuestion, UserQuiz, UserQuizAnswer } from "../model/Model"
import { API_URL, STATUS_CODE_OK } from "./Constants"

const QUIZ_ADDRESS = "/quiz/current"
const SAVE_QUIZ_ADDRESS = "/quiz/save"
const STATE_QUIZ_ADDRESS = "/quiz/state"
const STATE_QUIZ_ANSWER_ADDRESS = "/quiz/answer"

export function getQuizQuestion(callback: (quizQuestion: QuizQuestion) => void) {
  axios({
    method: "GET",
    url: API_URL + QUIZ_ADDRESS,
  }).then((response: AxiosResponse) => {
    callback(response.data)
  }).catch((error: AxiosError) => {
    console.log(error)
  })
}

export function getUserQuizState(callback: (quizQuestion: UserQuizAnswer[]) => void) {
  axios({
    method: "GET",
    url: API_URL + STATE_QUIZ_ADDRESS,
  }).then((response: AxiosResponse) => {
    callback(response.data)
  }).catch((error: AxiosError) => {
    console.log(error)
  })
}

export function saveAnswer(answerPk: number, callback: (successfullySaved: boolean) => void) {
  axios({
    method: "POST",
    url: API_URL + SAVE_QUIZ_ADDRESS,
    data: {
      pk: answerPk
    }
  }).then((response: AxiosResponse) => {
    callback(response.status === STATUS_CODE_OK)
  }).catch((error: AxiosError) => {
    console.log(error)
  })
}

export function getAnswer(answerPk: number, callback: (userQuiz: UserQuiz) => void) {
  axios({
    method: "POST",
    url: API_URL + STATE_QUIZ_ANSWER_ADDRESS,
    data: {
      pk: answerPk
    }
  }).then((response: AxiosResponse) => {
    callback(response.data)
  }).catch((error: AxiosError) => {
    console.log(error)
  })
}

