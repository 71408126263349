import { createStyles, makeStyles } from "@material-ui/core/styles"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import smile from "../../assets/smile.svg"
import victory from "../../assets/victory.svg"
import { Colors } from "../../common/Colors"
import Topbar from "../../common/components/Topbar"
import { TourEntrance, User } from "../../model/Model"
import { strings } from "../../translations"

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    congrats: {
      marginTop: "40px",
      color: Colors.green,
      fontSize: "20px",
      fontFamily: "Poppins-Bold"
    },
    name: {
      marginTop: "-20px",
      color: Colors.white,
      fontSize: "32px",
      fontFamily: "Raleway-Bold"
    },
    message: {
      marginTop: "30px",
      color: Colors.white,
      fontSize: "32px",
      fontFamily: "Raleway-Bold"
    },
    email: {
      marginTop: "-20px",
      marginBottom: "30px",
      color: Colors.softBlue2,
      fontSize: "16px",
      fontFamily: "Raleway-Medium"
    },
    otherMessage: {
      marginTop: "-20px",
      marginBottom: "30px",
      color: Colors.softBlue2,
      fontSize: "16px",
      fontFamily: "Raleway-Medium",
      width: "90%",
      textAlign: "center"
    },
    screenshotContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "50px"
    },
    screenshotContent: {
      width: "85%",
      borderRadius: "8px",
      backgroundColor: Colors.green,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    screenshotMessage: {
      color: Colors.white,
      fontSize: "16px",
      fontFamily: "Raleway-Medium",
      width: "90%",
      textAlign: "center",
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    smile: {
      marginTop: "-18px"
    }
  })
)

const Victory = () => {
  const classes = useStyles()

  const [user, setUser] = useState<User>({ name: "", email: "", tourEntrance : TourEntrance.Empty})

  const location = useLocation()

  useEffect(() => {
    setUser(location.state as User)
  }, [])

  return (
    <div className={classes.container}>
      <Topbar />
      <div className={classes.content}>
        <img src={victory} style={{ marginTop: "80px" }} />
        <p className={classes.congrats}>{strings.victoryCongrats}</p>
        <p className={classes.name}>{user.name}</p>
        <p className={classes.email}>{user.email}</p>    
        <p className={classes.message}>{strings.treasureYours}</p>
        {user.tourEntrance === TourEntrance.Virtual && <p className={classes.otherMessage}>{strings.virtualMessage}</p> }

        <div className={classes.screenshotContainer}>
          <div className={classes.screenshotContent}>
            <p className={classes.screenshotMessage}>{user.tourEntrance === TourEntrance.Real ?
              strings.screeshotReal : strings.screeshotVirtual}</p>
          </div>
          <img src={smile} className={classes.smile} />
        </div>
      </div>
    </div>
  )
}

export default Victory