import { createStyles, makeStyles } from "@material-ui/core/styles"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import emiliaRomagna from "../../assets/emilia_romagna.svg"
import favourites from "../../assets/favourites.svg"
import lepida from "../../assets/lepida.svg"
import { Colors } from "../../common/Colors"
import ItineraryListView from "../../common/components/ItineraryListView"
import TextField from "../../common/components/TextField"
import Topbar from "../../common/components/Topbar"
import { isEmailValid, isNotEmpty, openUrl } from "../../common/Utils"
import { PATH_CREDITS, PATH_FAVOURITES, PATH_PRIVACY_POLICY } from "../../Constants"
import { Itinerary, TourEntrance, User } from "../../model/Model"
import { getItineraries } from "../../services/ItineraryService"
import { getCurrentUser, saveUser } from "../../services/UserService"
import { strings } from "../../translations"

const ONSITE_PARAM = "onsite"

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    title: {
      marginTop: "20px",
      color: "white",
      fontSize: "22px",
      fontFamily: "Raleway-Bold"
    },
    subtitle: {
      marginTop: "14px",
      color: "white",
      fontSize: "16px",
      marginBottom: "15px",
      fontFamily: "Raleway-Medium"
    },
    checkboxContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "68%",
      marginTop: "10px"
    },
    checkbox: {
      borderColor: Colors.green,
      accentColor: Colors.green
    },
    checkboxText: {
      fontSize: "10px",
      color: Colors.white,
      fontFamily: "Raleway-Medium"
    },
    checkboxLinkText: {
      fontSize: "10px",
      color: Colors.green,
      marginLeft: "3px",
      fontFamily: "Raleway-Bold"
    },
    itinerariesContainer: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue,
      width: "100vw",
      justifyContent: "center",
      alignItems: "center"
    },
    itineraryTitleText: {
      marginTop: "30px",
      marginBottom: "16px",
      color: "white",
      fontSize: "16px",
      fontFamily: "Raleway-Medium"
    },
    redirectContainer: {
      display: "flex",
      flexDirection: "row",
      width: "90%",
      alignItems: "center",
      justifyContent: "space-between"
    },
    creditsLinkText: {
      fontSize: "14px",
      color: Colors.white,
      marginLeft: "10px",
      fontFamily: "Raleway-Bold"
    },
    favouriteLinkContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "10px"
    },
    favouriteLinkText: {
      fontSize: "14px",
      color: Colors.white,
      width: "100%",
      textAlign: "end",
      marginRight: "5px",
      fontFamily: "Raleway-Bold"
    },
    sponsorContainer: {
      backgroundColor: Colors.white,
      height: "75px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  })
)

const Home = () => {
  const classes = useStyles()
  const [checked, setChecked] = useState(false)
  const [itineraries, setItineraries] = useState<Itinerary[]>([])
  const [user, setUser] = useState<User>({ name: "", email: "", tourEntrance: TourEntrance.Virtual})
  const [modifiedName, setModifiedName] = useState("")
  const [modifiedEmail, setModifiedEmail] = useState("")

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  
  useEffect(() => {    
    const fetchCurrentUser = async () => {
      const tourEntrance = searchParams.has(ONSITE_PARAM) ? TourEntrance.Real : TourEntrance.Virtual
      getCurrentUser(tourEntrance, (user: User) => {
        setUser(user)
        setModifiedName(user.name)
        setModifiedEmail(user.email)
        setChecked(isNotEmpty(user.name) || isNotEmpty(user.email))
      })
    }
    const fetchItineraries = async () => {
      getItineraries((itineraries: Itinerary[]) => {
        setItineraries(itineraries) 
      })
    }
    fetchCurrentUser()
    fetchItineraries()
  }, [])
  
  function handleNameChanged(value: string) {
    setModifiedName(value)
  }

  function handleEmailChanged(value: string) {
    setModifiedEmail(value)
  }

  function onItineraryClick(itinerary: Itinerary) {
    if(!modifiedName && !modifiedEmail) {
      saveCurrentUser(itinerary.url)
    } else {
      if (checked) {
        if (!isEmailValid(user.email)) {
          alert(strings.invalidEmailMessage)
        } else {
          saveCurrentUser(itinerary.url)
        }
      } else {
        alert(strings.unselectedCheckboxMessage)
      }
    }
  }

  function saveCurrentUser(url: string) {
    const modifiedUser = { ...user, name: modifiedName, email: modifiedEmail }

    saveUser(modifiedUser, (successfullySaved: boolean) => {
      if(successfullySaved) {
        openUrl(url)
        setUser(modifiedUser)
      } else {
        alert(strings.saveError)
      }
    })
  }
    
  return (
    <div className={classes.container}>
      <Topbar homeButtonHidden={true}/>

      <div className={classes.content}>
        <label className={classes.title}>{strings.welcome}</label>
        <label className={classes.subtitle}>{strings.who}</label>

        <TextField onChange={handleNameChanged} placeholder={strings.fullName} value={modifiedName} type="text" />
        <TextField onChange={handleEmailChanged} placeholder={strings.email} value={modifiedEmail} type="email" />

        <div className={classes.checkboxContainer}>
          <input type="checkbox" className={classes.checkbox} checked={checked} onChange={() => {setChecked(!checked)}}/>

          <p className={classes.checkboxText}>{strings.termsAccept}</p>
          <p className={classes.checkboxLinkText} onClick={() => navigate(PATH_PRIVACY_POLICY)}>{strings.privacyPolicy}</p>
        </div>

        <p className={classes.itineraryTitleText}>{strings.chooseItinerary}</p>
        <div className={classes.itinerariesContainer}>
          <ItineraryListView itineraries={itineraries} onClick={onItineraryClick}/>
          <div className={classes.redirectContainer}>
            <p className={classes.creditsLinkText} onClick={() => navigate(PATH_CREDITS)}>{strings.credits}</p>
            
            {user.name !== "" || user.email !== "" ?
              <div className={classes.favouriteLinkContainer} >
                <p className={classes.favouriteLinkText} onClick={() => navigate(PATH_FAVOURITES)}>{strings.goToFavourites}</p>
                <img src={favourites} onClick={() => navigate(PATH_FAVOURITES)}/>
              </div>
              : 
              null 
            }
          </div>
        </div>
        <div className={classes.sponsorContainer}>
          <img src={emiliaRomagna} style={{height: "19px", width: "130px"}} />
          <img src={lepida} style={{ height: "26px", width: "56px", marginLeft: "50px" }} />
        </div>
      </div>
    </div>
  )
}

export default Home