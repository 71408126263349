import { createStyles, makeStyles } from "@material-ui/core/styles"
import { Colors } from "../../common/Colors"
import Topbar from "../../common/components/Topbar"
import { strings } from "../../translations"

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 auto",
      marginTop: "20px",
      width: "80%",
    },
    messageText: {
      fontSize: "16px",
      color: Colors.white,
      fontFamily: "Raleway-Medium"
    },
    linkText: {
      color: Colors.white
    }
  })
)

const Credits = () => {
  const classes = useStyles()
  
  return (
    <div className={classes.container}>
      <Topbar title={strings.credits} />

      <div className={classes.content}>
        <div className={classes.messageText}>

          {strings.getLanguage() === "it" ?
            <div>
              <p><span>In Italia le istituzioni museali si stanno adeguando alla trasformazione digitale in atto in tutti i settori della nostra societ&agrave; &ldquo;a macchia di leopardo&rdquo;: alcune hanno saputo comprendere le possibilit&agrave; di sviluppo legate alle nuove tecnologie, ma sono ancora la maggioranza quelle che procedono a rilento in questa trasformazione richiesta da un numero sempre maggiore di visitatori.</span></p>
              <p></p>
              <p><span>Questo &egrave; vero anche nel territorio emiliano-romagnolo sebbene recentemente, anche per effetto della pandemia e la conseguente necessit&agrave; di mantenere un rapporto con il pubblico attraverso le risorse on line, siano nate delle iniziative per risolvere questo problema (ad esempio il tavolo sulla digitalizzazione nei musei regionali). Le opportunit&agrave; offerte dalla tecnologia sono per&ograve; per lo pi&ugrave; ancora da scoprire.</span></p>
              <p></p>
              <p><a className={classes.linkText} href="https://www.lepida.net/"><b>Lepida S.c.p.A.</b></a><span>, su mandato di </span><a className={classes.linkText} href="https://www.regione.emilia-romagna.it/"><b>Regione Emilia-Romagna</b></a><span>, coordinamento </span><a className={classes.linkText} href="https://digitale.regione.emilia-romagna.it/"><b>Agenda Digitale</b></a><span>, ha deciso di realizzare dei </span><i><span>Test Before Invest </span></i><span>assieme ad alcuni musei del territorio per verificare l&rsquo;applicabilit&agrave; di alcune tecnologie.&nbsp;</span></p>
              <p></p>
              <p><span>Il progetto &ldquo;Fisica Experience Mobile Tour&rdquo;, sviluppato da </span><a className={classes.linkText} href="https://mangomobi.com/" ><b>Mango Mobile Agency</b></a><span> presso la sezione </span><a className={classes.linkText} href="https://www.fisicaexperience.it/"><b>Fisica Experience</b></a><span> del </span><a className={classes.linkText} href="http://www.museocieloeterra.org/"><b>Museo del Cielo e della Terra</b></a><span> del comune di </span><a className={classes.linkText} href="https://www.comunepersiceto.it/"><b>San Giovanni in Persiceto</b></a><span>, &egrave; relativo ad una </span><b>Web App</b><span>, che utilizzando immagini sferiche del Museo e modelli 3D, accompagni il visitatore fornendo informazioni aggiuntive sugli oggetti esposti nelle sale.</span></p>
            </div>
            :
            <div>
              <p><span>In Italy, museum institutions are adapting to the digital transformation taking place in all sectors of our &ldquo;patchy&ldquo; society: some have been able to understand the development possibilities linked to new technologies, but the majority are still those who proceed slowly in this transformation required by an increasing number of visitors.</span></p>
              <p></p>
              <p><span>This is also true in the Emilia-Romagna area although recently, also due to the pandemic and the consequent need to maintain a relationship with the public through online resources, initiatives have been created to solve this problem (for example the table on digitization in regional museums). However, the opportunities offered by technology are largely yet to be discovered.</span></p>
              <p></p>
              <p><a className={classes.linkText} href="https://www.lepida.net/"><b>Lepida ScpA</b></a><span>, on a mandate from the </span><a className={classes.linkText} href="https://www.regione.emilia-romagna.it/"><b>Emilia-Romagna region</b></a><span>, coordinating by </span><a className={classes.linkText} href="https://digitale.regione.emilia-romagna.it/"><b>Digital Agenda</b></a><span>, has decided to carry out some </span><i><span>Test Before Invest </span></i><span>with museums in the area to verify the applicability of new technologies.</span></p>
              <p></p>
              <p><span>The &ldquo; Fisica Experience Mobile Tour&ldquo; project, developed by </span><a className={classes.linkText} href="https://mangomobi.com/"><b>Mango Mobile Agency</b></a><span> at the </span><a className={classes.linkText} href="https://www.fisicaexperience.it/"><b>Fisica Experience</b></a><span> section of </span><a className={classes.linkText} href="http://www.museocieloeterra.org/"><b>Museo del Cielo e della Terra</b></a><span> in the municipality of </span><a className={classes.linkText} href="https://www.comunepersiceto.it/"><b>San Giovanni in Persiceto</b></a><span>, is related to a </span><b>Web App</b><span>, which using spherical images of the Museum and 3D models, accompany the visitor by providing additional information on the objects exhibited in the rooms.</span></p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Credits