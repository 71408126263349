import axios, { AxiosError, AxiosResponse } from "axios"
import { ModelData } from "../model/Model"
import { API_URL } from "./Constants"

const MODEL_ADDRESS = "/model/"

export function getModelData(modelName: string, callback: (model: ModelData | null) => void) {
  axios({
    method: "GET",
    url: API_URL + MODEL_ADDRESS + modelName,
  }).then((response: AxiosResponse) => {
    callback(response.data)
  }).catch((error: AxiosError) => {
    callback(null)
    console.log(error)
  })
}