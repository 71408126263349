import { createStyles, makeStyles } from "@material-ui/core/styles"
import artwork from "../../assets/artwork.svg"
import remove from "../../assets/remove.svg"
import { Favourite } from "../../model/Model"
import { Colors } from "../Colors"
import { openUrl } from "../Utils"

const useStyles = makeStyles(
  createStyles({
    favourite: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "5px",
      background: Colors.white,
      height: "100px",
      width: "100%",
      marginBottom: "15px"
    },
    artworkImage: {
      width: "54px",
      height: "54px",
      marginStart: "20px"
    },
    titleText: {
      color: Colors.black,
      fontSize: "18px",
      marginStart: "15px",
      marginTop: "25px",
      fontFamily: "Raleway-SemiBold"
    },
    itineraryNameText: {
      color: Colors.gray,
      fontSize: "8px",
      marginStart: "15px",
      marginTop: "-5px",
      fontFamily: "Raleway-Medium"
    },
    removeButton: {
      width: "14px",
      height: "14px",
      marginTop: "10px"
    }
  })
)

interface PropsType {
    onRemove: (favourite: Favourite) => void,
    favourite: Favourite
}

const FavouriteItem = (props: PropsType) => {
  const classes = useStyles()

  return (
    <div className={classes.favourite}>
      <div style={{ width: "20%" }} onClick={() => openUrl(props.favourite.url)}>
        <img className={classes.artworkImage} src={artwork} />
      </div>
      <div style={{ width: "74%", height: "100%" }} onClick={() => openUrl(props.favourite.url)}>
        <p className={classes.titleText}>{props.favourite.title}</p>
        <p className={classes.itineraryNameText}>{props.favourite.itineraryName}</p>
      </div>
      <div style={{ width: "6%", height: "100%" }}>
        <a onClick={() => { props.onRemove(props.favourite) }}>
          <img className={classes.removeButton} src={remove} />
        </a>
      </div>
    </div>
  )
}

export default FavouriteItem