import { createStyles, makeStyles } from "@material-ui/core/styles"
import { Colors } from "../../common/Colors"
import Topbar from "../../common/components/Topbar"
import { strings } from "../../translations"

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 auto",
      marginTop: "20px",
      width: "80%",
    },
    messageText: {
      fontSize: "16px",
      color: Colors.white,
      fontFamily: "Raleway-Medium"
    },
    linkText: {
      color: Colors.white
    }
  })
)

const PrivacyPolicy = () => {
  const classes = useStyles()
   
  return (
    <div className={classes.container}>
      <Topbar title={strings.privacyPolicy} />

      <div className={classes.content}>
        <div className={classes.messageText}>
          {strings.getLanguage() === "it" ?
            <div>
              <p><span>La presente informativa viene resa ai sensi degli artt. 13 e 14 del Regolamento Europeo 2016/679 in materia di protezione dei dati personali, tenendo conto della Raccomandazione n. 2/2001 adottata il 17 Maggio 2001, agli utenti che utilizzano il servizio offerto dalla WEB APP</span>
                <b> FISICA EXPERIENCE MOBILE TOUR</b>
                <span> ed &egrave; relativa a tutti i dati personali che </span>
                <b>COMUNE DI SAN GIOVANNI IN PERSICETO</b><span> raccoglie e detiene sui propri utenti.</span>
              </p>
              <p><span>L&apos;informativa &egrave; resa per la WEB APP </span>
                <b>FISICA EXPERIENCE MOBILE TOUR </b>
                <span>e non anche per altri siti web e/o applicazioni eventualmente consultati dall&apos;utente tramite link a pagine esterne di terzi.&nbsp;</span>
              </p>
              <p></p>
              <p><b>TITOLARE DEL TRATTAMENTO</b></p>
              <p><span>Il titolare del trattamento &egrave;:</span></p>
              <p><b>COMUNE DI SAN GIOVANNI IN PERSICETO</b></p>
              <p><span>Sede legale:</span></p>
              <p><b>Corso Italia 70 &ndash; 40017 San Giovanni in Persiceto (BO)</b></p>
              <p><span>Indirizzo email: </span><a className={classes.linkText} href="mailto:webmaster@comunepersiceto.it">webmaster@comunepersiceto.it</a></p>
              <p><span>&nbsp;</span></p>
              <p><b>DATI RACCOLTI</b></p>
              <p><span>I dati personali raccolti e trattati attraverso la WEB APP sono riferibili alle seguenti tipologie. L&rsquo;utente pu&ograve; inserire nell&rsquo;apposito form i dati personali relativi a:</span></p>
              <ul>
                <li><span>Nome e Cognome</span></li>
                <li><span>Indirizzo email</span></li>
              </ul>
              <p></p>
              <p><b>TRACCIAMENTO</b></p>
              <p><span>La presente app fa uso di servizi di tracciamento nei quali vengono conservate informazioni raccolte in maniera automatizzata e in forma esclusivamente aggregata al fine di verificare il corretto funzionamento della WEBAPP, e per effettuare verifiche ed ottimizzazioni del servizio. Nessuna di queste informazioni è correlata alla persona fisica-utente dell&apos;app, e non ne consentono in alcun modo l&apos;identificazione.</span></p>
              <p></p>
              <p><b>DESTINATARI</b></p>
              <p><span>I dati di natura personale forniti potranno essere comunicati a destinatari opportunamente nominati che tratteranno i dati in qualit&agrave; di responsabili per il trattamento e/o in qualit&agrave; di incaricati.</span></p>
              <p><span>&Egrave; in ogni caso esclusa la diffusione dei dati personali trattati.</span></p>
              <p><span>L&rsquo;elenco completo dei responsabili per il trattamento e degli incaricati al trattamento dei dati personali pu&ograve; essere richiesto inviando apposita domanda agli estremi di contatto del Titolare indicati in questo documento.</span></p>
              <p></p>
              <p><b>FINALITA&rsquo; DEL TRATTAMENTO</b></p>
              <p><span>I dati sono trattati esclusivamente per le finalit&agrave; per le quali sono raccolti, come di seguito descritte. Il Titolare del trattamento utilizza i dati forniti dagli interessati per:</span></p>
              <ol>
                <li><span> Esecuzione dei servizi resi disponibili attraverso la WEBAPP;</span></li>
                <li><span> Gestire l&rsquo;account degli utenti registrati per fruire dei relativi servizi offerti dal Titolare del trattamento;</span></li>
                <li><span> Adempiere a obblighi di legge, regolamento, normativa comunitaria;</span></li>
              </ol>
              <p></p>
              <p><span>Il conferimento dei dati obbligatori ed il rifiuto a prestare il relativo consenso al Trattamento permette comunque di usufruire della maggior parte dei servizi resi disponibili attraverso l&rsquo;applicazione, ma dar&agrave; luogo all&rsquo;impossibilit&agrave; di essere aggiornati circa iniziative commerciali e/o campagne promozionali, di ricevere offerte o altro materiale promozionale.&nbsp;</span></p>
              <p></p>
              <p><b>MODALITA&rsquo; DEL TRATTAMENTO</b></p>
              <p><span>I dati personali sono trattati con strumenti automatizzati e manuali per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti.</span></p>
              <p><span>Non appena i dati personali non sono pi&ugrave; necessari per gli scopi per i quali sono stati raccolti, il Titolare del trattamento provvede alla loro eliminazione salvo che la legge non preveda obblighi di archiviazione o l&rsquo;utente non abbia acconsentito al trattamento per un tempo pi&ugrave; lungo.</span></p>
              <p><span>Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati. La sicurezza nella gestione dei dati personali &egrave; garantita anche dall&apos;utilizzo della password personale dell&apos;utente che dovr&agrave; essere custodita con la dovuta diligenza e attenzione.</span></p>
              <p></p>
              <p><b>DIRITTI DELL&rsquo;INTERESSATO</b></p>
              <p><span>Ai sensi degli artt. 15 &ndash; 22 del Regolamento UE 2016/679 a ciascun interessato vengono riconosciuti una serie di diritti:</span></p>
              <ul>
                <li><span>diritto di accesso</span></li>
                <li><span>diritto di rettifica</span></li>
                <li><span>diritto all&rsquo;eliminazione dei dati</span></li>
                <li><span>diritto di limitazione di trattamento</span></li>
                <li><span>diritto alla portabilit&agrave; dei dati</span></li>
                <li><span>diritto di opposizione al trattamento</span></li>
              </ul>
              <p><span>L&rsquo;interessato ha altres&igrave; diritto di proporre reclamo all&rsquo;Autorit&agrave; di controllo competente, il Garante della Privacy.</span></p>
              <p><span>&nbsp;</span></p>
              <p><span>Le richieste di cui ai punti precedenti e, nello specifico, la rimozione dei dati personali, dovranno essere rivolte per iscritto via email o posta ordinaria agli estremi di contatto del Titolare, indicati in questo documento.</span></p>
              <p></p>
              <p><span>Il Titolare del trattamento provveder&agrave;, nei limiti temporali stabiliti dalla vigente normativa, a fornire tempestiva risposta alle richieste di esercizio dei diritti degli interessati. Ogni delucidazione o richiesta di chiarimenti pu&ograve; essere rivolta per iscritto al Titolare del trattamento.</span></p>        
            </div>
            :
            <div>
              <p><span>This information is provided pursuant to art. 13 and 14 of the 2016/679 European Regulation on the protection of personal data, taking into account the Recommendation no. 2/2001 adopted on May 17, 2001, to users who use the service offered by the WEB APP</span><b> FISICA EXPERIENCE MOBILE TOUR</b><span> and relates to all personal data that </span><b>COMUNE DI SAN GIOVANNI IN PERSICETO</b><span> collects and holds about its users.</span></p>
              <p><span>The information is provided for the WEB APP </span>
                <b>FISICA EXPERIENCE MOBILE TOUR </b>
                <span>and not for other websites and / or applications that may be consulted by the user through links to external pages of third parties.&nbsp;</span></p>
              <p></p>
              <p><b>DATA CONTROLLER</b></p>
              <p><span>The data controller is:</span></p>
              <p><b>MUNICIPALITY OF SAN GIOVANNI IN PERSICETO</b></p>
              <p><span>Registered office:</span></p>
              <p><b>Corso Italia 70 - 40017 San Giovanni in Persiceto (BO)</b></p>
              <p><span>Email address: </span><a className={classes.linkText} href="mailto:webmaster@comunepersiceto.it">webmaster@comunepersiceto.it</a></p>
              <p><span>&nbsp;</span></p>
              <p><b>DATA COLLECTED</b></p>
              <p><span>The personal data collected and processed through the WEB APP can be referred to the following types. The user can enter in the appropriate form the personal data relating to:</span></p>
              <ul>
                <li><span>Name and Surname</span></li>
                <li><span>Email address</span></li>
              </ul>
              <p></p>
              <p><b>TRACKING</b></p>
              <p><span>This app makes use of tracking services in which information collected in an automated and exclusively aggregated form is stored in order to verify correct functioning of the WEBAPP, and to carry out checks and optimizations of the service. None of this information is related to the physical person-user of the app, and does not allow identification in any way.</span></p>
              <p></p>
              <p><b>RECIPIENTS</b></p>
              <p><span>The personal data provided may be disclosed to appropriately appointed recipients who will process the data as data processors and / or as persons in charge.</span></p>
              <p><span>In any case, the dissemination of personal data processed is excluded.</span></p>
              <p><span>The complete list of data processors and persons in charge of processing personal data can be requested by sending a specific request to the contact details of the Data Controller indicated in this document.</span></p>
              <p></p>
              <p><b>PURPOSE OF THE TREATMENT</b></p>
              <p><span>The data are processed exclusively for the purposes for which they are collected, as described below. The Data Controller uses the data provided by the interested parties for:</span></p>
              <ol>
                <li><span> Execution of the services made available through the WEBAPP;</span></li>
                <li><span> Manage the account of registered users to use the related services offered by the Data Controller;</span></li>
                <li><span> Fulfill the obligations of law, regulation, community legislation;</span></li>
              </ol>
              <p></p>
              <p><span>The provision of mandatory data and the refusal to give the relative consent to the processing still allows you to use most of the services made available through the application, but will result in the impossibility of being updated on commercial initiatives and / or promotional campaigns, receive offers or other promotional material.&nbsp;</span></p>
              <p></p>
              <p><b>PROCESSING METHOD</b></p>
              <p><span>Personal data are processed with automated and manual tools for the time strictly necessary to achieve the purposes for which they were collected.</span></p>
              <p><span>As soon as the personal data are no longer necessary for the purposes for which they were collected, the Data Controller deletes them unless the law provides for archiving obligations or the user has consented to the processing for a longer time. .</span></p>
              <p><span>Specific security measures are observed to prevent data loss, illicit or incorrect use and unauthorized access.The security in the management of personal data is also guaranteed by the use of the user&apos;s personal password which must be kept with due diligence and attention.</span></p>
              <p></p>
              <p><b>RIGHTS OF THE INTERESTED PARTY</b></p>
              <p><span>Pursuant to articles 15 - 22 of EU Regulation 2016/679 each interested party is granted a series of rights:</span></p>
              <ul>
                <li><span>right of access</span></li>
                <li><span>right of rectification</span></li>
                <li><span>right to delete data</span></li>
                <li><span>right to limitation of treatment</span></li>
                <li><span>right to data portability</span></li>
                <li><span>right to object to processing</span></li>
              </ul>
              <p><span>The interested party has also the right to lodge a complaint with the competent supervisory authority, the Privacy Guarantor.</span></p>
              <p><span>&nbsp;</span></p>
              <p><span>The requests referred to in the previous points and, specifically, the removal of personal data, must be sent in writing via email or ordinary mail to the contact details of the Data Controller, indicated in this document.</span></p>
              <p></p>
              <p><span>The Data Controller will, within the time limits established by current legislation, provide a timely response to requests for the exercise of the rights of the interested parties. Any clarification or request for clarification can be addressed in writing to the Data Controller.</span></p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy