import { createStyles, makeStyles } from "@material-ui/core"
import { Html, OrbitControls, Stage, useProgress } from "@react-three/drei"
import { Canvas, useLoader } from "@react-three/fiber"
import { Suspense, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import threesixty from "../../assets/360.svg"
import fullscreen from "../../assets/fullscreen.svg"
import { Colors } from "../../common/Colors"
import Topbar from "../../common/components/Topbar"
import { ModelData } from "../../model/Model"
import { getModelData } from "../../services/ModelService"
import { strings } from "../../translations"

const useStyles = makeStyles(() =>
  createStyles({
    page: {
      display: "flex",
      flexDirection: "column",
      background: Colors.blue,
      height: "100vh"
    },
    header: {
      position: "relative",
      display: "flex",
      height: "70px",
      justifyContent: "center",
      alignItems: "center"
    },
    home: {
      position: "absolute",
      display: "flex",
      left: "25px",
    },
    logo: {
      align: "center"
    },
    content: {
      marginLeft: "23px",
      marginRight: "23px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    title: {
      color: "white",
      fontSize: "22px",
      fontFamily: "Raleway-Bold"
    },
    description: {
      marginTop: "0px",
      marginBottom: "36px",
      textAlign: "center",
      color: "white",
      fontSize: "16px",
      fontFamily: "Raleway-Medium"
    },
    //
    container: {
      position: "relative",
      margin: "0 23px 0 23px",
      height: "439px",
      borderRadius: "5px",
      overflow: "hidden"
    },
    fullscreen: {
      position: "absolute",
      top: "14px",
      right: "14px",
      width: "26px",
      height: "26px",
    },
    threesixty: {
      position: "absolute",
      top: "22px",
      left: "16px",
      width: "29px",
      height: "13px",
    },
    close: {
      position: "absolute",
      top: "25px",
      right: "30px",
      color: Colors.green,
      fontSize: "17px",
      fontFamily: "Raleway-Bold"
    }
  })
)

const defaultCanvasStyle = {
  height: "439px",
  margin: "0 23px 0 23px",
  overflow: "hidden"
}
const fullCanvasStyle = {
  height: "100vh",
  margin: "0",
  overflow: "visible"
}

const PARAM_NAME = "name"

interface Model3DProps {
  data: ModelData
}

const Model3D = (props: Model3DProps) => {
  const publicUrl = process.env.PUBLIC_URL.length > 0 ? process.env.PUBLIC_URL : "."
  const result = useLoader(GLTFLoader, `${publicUrl}/models/${props.data.filename}`, (loader) => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath("/draco-gltf/");
    (loader as GLTFLoader).setDRACOLoader(dracoLoader)
  })
  const [x, y, z] = props.data.position
  result.scene.position.set(x, y, z)
  const [scaleX, scaleY, scaleZ] = props.data.scale
  result.scene.scale.set(scaleX, scaleY, scaleZ)
  return <primitive object={result.scene} />
}

const Model3DLoader = () => {
  const { progress } = useProgress()
  return <Html center>{Math.trunc(progress)}% {strings.loaded}</Html>
}

const Artwork3DModel = () => {
  const classes = useStyles()
  const [canvasStyle, setCanvasStyle] = useState(defaultCanvasStyle)
  const [fullScreen, setFullScreen] = useState(false)

  const [model, setModel] = useState<ModelData | null>(null)
  const [modelFound, setModelFound] = useState<boolean | null>(null)

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const fetchModelData = async (modelName: string) => {
      getModelData(modelName, (modelData: ModelData | null) => {
        if (modelData !== null) {
          setModelFound(true)
          setModel(modelData)
        } else {
          setModelFound(false)
        }
      })
    }

    const modelName = searchParams.get(PARAM_NAME)
    if (modelName !== null) {
      fetchModelData(modelName)
    } else {
      setModelFound(false)
    }
  }, [searchParams])

  function onFullScreenOpen() {
    setFullScreen(true)
    setCanvasStyle(fullCanvasStyle)
  }

  function onFullScreenClose() {
    setFullScreen(false)
    setCanvasStyle(defaultCanvasStyle)
  }

  return (
    <div className={classes.page}>
      {!fullScreen && <Topbar />}

      <div className={classes.content} style={{ display: fullScreen ? "none" : "flex" }}>
        <p className={classes.title}>{model !== null ? model.title : ""}</p>
        <p className={classes.description}>{model !== null ? model.description : ""}</p>
      </div>

      {model !== null &&
        <div className={classes.container} style={canvasStyle}>
          <Canvas camera={{ position: [5, 2, 8] }}>
            <color attach="background" args={[191, 227, 221]} />
            <OrbitControls enablePan={false} enableDamping={true} target={[0, 0.5, 0]} />
            {/* <pointLight color={"white"} intensity={5} distance={28} decay={2} position={[0.418, 16.199, 0.300]}/>
        <Model3D/> */}
            <Suspense fallback={<Model3DLoader />}>
              <Stage environment={"lobby"} intensity={0.45} shadows={"contact"}>
                <Model3D data={model} />
              </Stage>
            </Suspense>
            {/* <Suspense fallback={<Model3DLoader/>}>
              <Model3D data={model}/>
            </Suspense>
            <ambientLight intensity={0.5} />
            <spotLight position={[10, 15, 10]} angle={0.3} /> */}
          </Canvas>
          <img
            className={classes.threesixty}
            style={{ display: fullScreen ? "none" : "block" }}
            src={threesixty} />
          <img
            className={classes.fullscreen}
            style={{ display: fullScreen ? "none" : "block" }}
            src={fullscreen}
            onClick={() => onFullScreenOpen()} />
          <span
            className={classes.close}
            style={{ display: fullScreen ? "inline" : "none" }}
            onClick={() => onFullScreenClose()}>
            {strings.close}
          </span>
        </div>
      }
      {modelFound !== null && !modelFound && <p className={classes.description}>{strings.noModelFound}</p>}
    </div>
  )
}

export default Artwork3DModel