import { createStyles, makeStyles } from "@material-ui/core/styles"
import next from "../../assets/itinerary_next.svg"
import { Itinerary } from "../../model/Model"
import { Colors } from "../Colors"

const useStyles = makeStyles(
  createStyles({
    itineraryButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "5px",
      background: Colors.green,
      height: "80px",
      marginBottom: "13px"
    },
    itineraryLabelText: {
      color: "white",
      fontSize: "18px",
      marginLeft: "29px",
      width: "90%",
      fontFamily: "Raleway-Bold"
    },
    nextButton: {
      width: "10%",
      height: "33px",
      marginEnd: "28px",
      marginTop: "5px",
    }
  })
)

interface PropsType {
    onClick: (itinerary: Itinerary) => void,
    itinerary: Itinerary
}

const ItineraryButton = (props: PropsType) => {
  const classes = useStyles()
    
  return (
    <a onClick={() => { props.onClick(props.itinerary)}} 
      style={{color: Colors.green}}>
      <div className={classes.itineraryButton}>
        <p className={classes.itineraryLabelText}>{props.itinerary.name}</p>
        <img className={classes.nextButton} src={next} />
      </div> 
    </a>   
  )
}

export default ItineraryButton