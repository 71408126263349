export const PATH_HOME = "/"
export const PATH_CREDITS = "/credits"
export const PATH_FAVOURITES = "/favourites"
export const PATH_PRIVACY_POLICY = "/privacy-policy"
export const PATH_MODEL3D = "/model3d"
export const PATH_QUIZ_QUESTION = "/quiz/question"
export const PATH_QUIZ_ANSWER = "/quiz/answer"
export const PATH_QUIZ_RESULT = "/quiz/result"
export const PATH_QUIZ_VICTORY = "/quiz/victory"
export const PATH_QUIZ_VICTORY_FILL_DATA = "/quiz/victoryFillData"
