import List from "@material-ui/core/List"
import { Favourite } from "../../model/Model"
import FavouriteItem from "./FavouriteItem"

interface PropsType {
    favourites: Favourite[],
    onRemove: (favourite: Favourite) => void
}

export default function FavouriteListView(props: PropsType) {
  return (
    <List style={{ width: "90%" }}>
      {props.favourites.map((favourite: Favourite) => (
        <FavouriteItem key={favourite.pk} favourite={favourite} onRemove={props.onRemove}/>
      ))}
    </List>
  )
}