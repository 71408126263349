import axios, { AxiosError, AxiosResponse } from "axios"
import { Favourite } from "../model/Model"
import { strings } from "../translations"
import { API_URL, STATUS_CODE_OK } from "./Constants"

const FAVOURITES_ADDRESS = "/favourites/all"
const SAVE_FAVOURITE_ADDRESS = "/favourites/save"
const REMOVE_FAVOURITE_ADDRESS = "/favourites/remove"
const SEND_FAVOURITE_ADDRESS = "/favourites/send"

export function getFavourites(callback: (favourites: Array<Favourite>) => void) {
  axios({
    method: "GET",
    url: API_URL + FAVOURITES_ADDRESS
  }).then((response: AxiosResponse) => {
    callback(response.data)
  }).catch((error: AxiosError) => {
    console.log(error)
  })
}

export function saveFavourite(artworkPk: number, callback: (favourites: Array<Favourite>) => void) {
  axios({
    method: "POST",
    url: API_URL + SAVE_FAVOURITE_ADDRESS,
    data: {
      pk: artworkPk
    }
  }).then((response: AxiosResponse) => {
    callback(response.data)
  }).catch((error: AxiosError) => {
    console.log(error)
  })
}

export function removeFavourite(favouritePk: number, callback: (favourites: Array<Favourite>) => void) {
  axios({
    method: "POST",
    url: API_URL + REMOVE_FAVOURITE_ADDRESS,
    data: {
      pk: favouritePk
    }  
  }).then((response: AxiosResponse) => {
    callback(response.data)
  }).catch((error: AxiosError) => {
    console.log(error)
  })
}

export function sendEmailList(email: string, callback: (emailSuccessfullySent: boolean) => void) {
  axios({
    method: "POST",
    url: API_URL + SEND_FAVOURITE_ADDRESS,
    data: {
      email: email
    }
  }).then((response: AxiosResponse) => {
    callback(response.status === STATUS_CODE_OK)
  }).catch(() => {
    alert(strings.emailSendFailureMessage)
  })
}